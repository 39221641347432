.container_testimonials {
	border-radius: 20px;
	padding-top: 5%;
	padding-bottom: 5%;
	margin-top: 15%;
	z-index: 3;
	margin-left: auto;
	margin-right: auto;
	width: 90vh;
	height: 80vh;
	display: flex;
	justify-content: center;

}
.testimonial {

	.tiles-item-inner {
        color: white;
		background: get-color(primary, 1);		
        
	}
    h1 {
        font-size: 2.5rem;  
		text-align: center;
		text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.585);
    }
    h2 {
        font-size: 1.2rem;
        color: get-color(primary, 1);

        text-align: center;
    }
}

.container-test {
    border-radius: 50px;
    padding-right: 30px;
    width: 350px;
    height: 350px;
}

.container-slider {

    width: 90%;


}

.testimonial-item-content {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 24px;
        height: 18px;
        margin-top: 12px;
        margin-bottom: 16px;
       
    }		
}

.testimonial-item-footer {
    font-weight: 600;
    padding-top: 20px;

    &.has-top-divider {

        &::before {
            background: get-color(light, 3);
        }
    }
}

.testimonial-item-link {
	color: get-color(secondary, 3);

	a {
        color: get-color(light, 3);
        text-decoration: none;

        &:hover {
            color: get-color(primary, 2);
        }
	}
}