.container_about_us {
	border-radius: 20px;
	padding-top: 5%;
	padding-bottom: 5%;
	margin-top: 15%;
	z-index: 3;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	width: 100%;
	height: 70vh;
	display: flex;
	justify-content: center;

	h1 {
		font-size: 2rem;  
		text-align: center;
		text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.585);
	}
	h3 {
		font-size: 1rem;  
		color: rgb(241, 241, 241);
		text-shadow: 1px 1px 1px rgba(19, 19, 19, 0.585);
	}
}

.container_about_us .cont_image{
	z-index: 2;
	height: 100%;
	width: 45%;


}

.container_about_us .cont_text {
	background-color: #ff9808;
	z-index: 2;
	height: 100%;
	width: 45%;
}

.container_about_us .title_text{
	padding: 5%;
	z-index: 2;
    height: 15%;
	width: 100%;	
}
.container_about_us .text{
	padding: 5%;
	z-index: 2;
	height: 85%;
	width: 100%;	
}
.container_about_us .img {
	height: 100%;
	width: 100%;
	
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../../../images/6661-01.svg);

	object-fit: cover;
}