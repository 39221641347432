.is-boxed {
    background: color-bg(body-outer);

	.body-wrap {
		max-width: $container--width-boxed;
	    margin: 0 auto;
	    box-shadow: color-bg(body-shadow);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: color-bg(body);
			z-index: -10;
		}
	}
}

.body-wrap {
	position: relative;
    overflow: hidden;
    // Sticky footer
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
	background-color: #f1e3d6; /*rgb(255, 255, 255);*/
    flex: 1 0 auto;
}


////////////////////
/// 
.circles{
position: absolute;
margin-bottom: -50%;
top: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
}

.circles li{
z-index: 0;
position: fixed;
display: block;
list-style: none;
width: 20px;
height: 20px;
background: rgba(255, 128, 0, 0.422);
animation: animate 25s linear infinite;
bottom: -200px;

}

.circles li:nth-child(1){
left: 25%;
width: 80px;
height: 80px;
animation-delay: 0s;
}


.circles li:nth-child(2){
left: 10%;
width: 20px;
height: 20px;
animation-delay: 2s;
animation-duration: 5s;
}

.circles li:nth-child(3){
left: 70%;
width: 20px;
height: 20px;
animation-delay: 4s;
}

.circles li:nth-child(4){
left: 40%;
width: 60px;
height: 60px;
animation-delay: 0s;
animation-duration: 18s;
}

.circles li:nth-child(5){
left: 65%;
bottom: -15%;
width: 20px;
height: 20px;
animation-delay: 4s;
}

.circles li:nth-child(6){
left: 75%;
width: 110px;
height: 110px;
animation-delay: 3s;
}

.circles li:nth-child(7){
left: 35%;
width: 150px;
height: 150px;
animation-delay: 15s;
}

.circles li:nth-child(8){
left: 50%;
width: 25px;
height: 25px;
animation-delay: 15s;
animation-duration: 45s;
}

.circles li:nth-child(9){
left: 20%;
width: 15px;
height: 15px;
animation-delay: 2s;
animation-duration: 35s;
}

.circles li:nth-child(10){
left: 85%;
width: 150px;
height: 150px;
animation-delay: 3s;
animation-duration: 11s;
}



@keyframes animate {

0%{
	transform: translateY(0) rotate(0deg);
	opacity: 1;
	border-radius: 0;
}

100%{
	transform: translateY(-1000px) rotate(720deg);
	opacity: 0;
	border-radius: 10%;
}

}
