.container-form{
    width: 100%;
    background-color: #ff9808;
}
.cta {

    .form-input {
        margin: 5%;
        border-color: transparent;
        border-radius: 2px;
        .form-input2 {
            height: 20%;
            border-color: transparent;
            border-radius: 2px;
        }
    }

}
.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
    background-color: get-color(primary, 3);
	background-image: url(../../../images/cta-illustration.svg);
	background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;    
}

@include media( '>medium' ) {

.cta {

    .form-input {
        min-width: 500px;

    }
}
    

	.cta-inner {
		padding-left: 10%;
		padding-right: 10%;
	}

}